import "react-sliding-pane/dist/react-sliding-pane.css";

import { useCallback, useEffect, useRef, useState } from "react";
import { getAllTickets, qPayload } from "../../../adapters";
import { ColumnDirective, ColumnsDirective, DataStateChangeEventArgs, GridComponent, Toolbar } from "@syncfusion/ej2-react-grids";
import { Inject, Page, Sort } from "@syncfusion/ej2-react-grids";
import ReactSlidingPane from "react-sliding-pane";
import dayjs from "dayjs";
import MemberInsight from "./MemberInsight";
import { ClipLoader } from "react-spinners";
import { debounce, isArray } from "lodash";
import { emit } from "../../../util/ibuki";
import { TableLoader } from "../../../components/widgets/SkeletonScreens";
import { PlusCircleIcon, ChatIcon, XIcon, CheckIcon, EyeIcon, BriefcaseIcon, InformationCircleIcon } from "@heroicons/react/solid";
import { useGetAdminConfig } from "../adminHooks";
import Select from "react-select";
import { ThemeTooltip } from "../../../components/settings/theme/ThemeTooltip";

const placeholder = "Search by title or body text";
interface IWorkItemQ {
	data: { results: { title: string; createdOn: string }; totalResults: string };
}

// export const getClassifications = () => {
// 	// @todo: vk - get all classifications from workflow api - custom care is the only available type currently
// 	return [
// 		{ value: "", label: "Select Message Type"},
// 		{ value: 1, label: "Customer Care" }
// 	];
// }

const MemberInsights = () => {
	const [showSidePanel, setShowSidePanel] = useState(false);
	const [isProcessing, setIsProcessing] = useState(false);
	const [firstLoad, setFirstLoad] = useState(true);
	const [workItemId, setWorkItemId] = useState("");
	const [activePage, setActivePage] = useState(0);
	const [data, setData] = useState<{ result: IWorkItemQ["data"]["results"]; count: string }>();
	const [sortParams, setSortParams] = useState<any[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [tooltip, setTooltip] = useState<string>("");
	// const [classificationFilter, setClassificationFilter] = useState<string>();
	const gridControl = useRef<any>("");
	const adminConfig = useGetAdminConfig();
	// const classifications = getClassifications();

	useEffect(() => {
		if (adminConfig.isBroker) {
			setTooltip("Member Insight data contains Customer Support tickets from 7/16/24 forward only.");
		} else {
			setTooltip("Member Insight data contains Customer Support tickets from 7/16/24 forward only.<br />Customer Support and Compliance tickets created prior to 7/16/24 can be viewed<br />by clicking the Member Insights tile in your dashboard apps.");
		}
		return () => setTooltip("")
	}, [adminConfig]);

	useEffect(() => {
		fetchData();
		return () => {};
	}, [activePage, sortParams, searchTerm]);

	const fetchData = () => {
		const payload: qPayload = {
			pageId: activePage,
			pageSize: adminConfig.pageSize,
			orderBy: sortParams,
			criteria: [],
			searchTerm: ""
		}
		if (searchTerm) {
			payload.searchTerm = searchTerm;
		}
		// if (classificationFilter) {
		// 	payload.criteria.push({ field: "workItemDefinitionClassificationName", op: "Equal", values: [classificationFilter]})
		// }
		getAllTickets(payload).then((response: IWorkItemQ) => {
			if (gridControl.current) {
				gridControl.current.hideSpinner();
			}
			setData({
				result: response.data.results,
				count: response.data.totalResults,
			});
			setFirstLoad(false);
		});
		setIsProcessing(false);
	};

	const dateFormatter = (field: string, data: any) => {
		return dayjs(data[field]).format("MM/DD/YYYY");
	};

	const handleStateChange = (state: DataStateChangeEventArgs) => {
		if (state.action) {
			if (state.action.requestType === "sorting") {
				if ("sorted" in state) {
					let sortOrder: any[] = [];
					state.sorted?.forEach((sort) => {
						sortOrder.push({
							field: sort.name,
							direction: sort.direction === "ascending" ? "Asc" : "Desc",
						});
					});
					setSortParams(sortOrder);
				} else {
					setSortParams([]);
				}
			} else if (state.action.requestType === "paging") {
				if ((state.action as any).currentPage - 1 !== activePage) {
					setActivePage((state.action as any).currentPage - 1);
				}
			}
		} else {
			state.skip = 1;
		}
	};

	const handleResetSearch = () => {
		const insightSearchInput = document.getElementById("insight-search") as HTMLInputElement;
		if (insightSearchInput) {
			insightSearchInput.value = "";
		}
		setSearchTerm("");
	}

	const handleSearch = (searchTerm: string) => {
		debounceSearch.cancel();
		setSearchTerm(searchTerm);
	};
	const debounceSearch = debounce(handleSearch, 500);
	const debouncedHandleSearch = useCallback(debounceSearch, []);

	const handleToolbarClick = (args: any) => {
		if (args.item.id === "RefreshView") {
			fetchData();
		}
	};

	const nameTemplate = (props: any) => {
		let field = props.column.field; // this will be the actual column
		let tag = props[field] ?? "No Name";
		if (isArray(tag)) {
			tag = tag.join(", ");
		}
		return (
			<div className="cursor-pointer" onClick={() => handleViewInsight(props.id)}>
				{tag}
			</div>
		);
	};

	const booleanTemplate = (props: any) => {
		let value = props[props.column.field]; // this will be the actual value of the column
		if (value) {
			return (
				<span className={"text-green-500"}>
					<CheckIcon className="w-5 h-5" />
				</span>
			);
		} else {
			return (
				<span className={"text-red-500"}>
					<XIcon className="w-5 h-5" />
				</span>
			);
		}
	};

	const actionsTemplate = (props: any) => {
		return (
			<span className="relative z-0 inline-flex shadow-sm rounded-sm">
				<button title="View" type="button" className="relative inline-flex items-center px-2 py-2 rounded-l-sm border border-default bg-primary" onClick={() => handleViewInsight(props.id)}>
					<span className="sr-only">View</span>
					<EyeIcon className="w-4 h-4 text-header" aria-hidden="true" />
				</button>
			</span>
		);
	};

	const handleViewInsight = (insightId: string) => {
		setWorkItemId(insightId);
		setShowSidePanel(true);
	};

	return firstLoad ? (
		<TableLoader />
	) : (
		<>
			<div className="inline-flex mb-4 pl-2 opacity-90"><BriefcaseIcon className="h-8 w-8 mr-2" /><h3 className="font-medium text-2xl">Cases <InformationCircleIcon className="w-5 h-5 text-red-500 inline cursor-pointer" data-tip={tooltip} /></h3></div>
			<div className="crmls-table-main-actions">
				<div className="crmls-actions-left w-full lg:w-3/4">
					{!adminConfig.isBroker &&
						<button type="button" className="action-button bg-green-600 w-full sm:w-auto" onClick={() => emit("openSupportTicket")}>
							<PlusCircleIcon className="h-4 w-4 mr-1" /> New Support Ticket
						</button>
					}

					<button type="button" className="action-button bg-header w-full sm:w-auto" onClick={() => emit("openSupportChat")}>
						<ChatIcon className="h-4 w-4 mr-1" /> Message Support Now
					</button>
				</div>
				<div className="crmls-actions-right w-full lg:w-1/4">
					{/* <select 
						className="mr-2 input-registered-required" 
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setClassificationFilter(e.target.value)}
						value={classificationFilter ?? ""}
						>
						{classifications.map((classification, i) => {
							return <option key={i} value={classification.value}>{classification.label}</option>;
						})}
					</select> */}
					<input
						id="insight-search"
						type="text"
						className="w-full input-registered-required"
						placeholder={placeholder}
						onChange={(e) => {
							setIsProcessing(true);
							debouncedHandleSearch(e.target.value);
						}}
					/>
					{!isProcessing && searchTerm && <XIcon className="cursor-pointer w-5 h-5 absolute top-1/2 transform -translate-y-1/2 right-3" onClick={handleResetSearch} />}
					{isProcessing && <div  className="absolute top-1/2 transform -translate-y-1/2 right-3"><ClipLoader aria-label="Loading Results" size={14} /></div>}
				</div>
			</div>
			<div className="syncfusion-grid w-full">
				<GridComponent
					ref={gridControl}
					dataStateChange={handleStateChange}
					dataSource={data}
					allowPaging={true}
					pageSettings={{ pageCount: 10, pageSize: 10 }}
					allowSorting={true}
					resizeSettings={{ mode: "Auto" }}
					dataBound={() => {
						gridControl.current.autoFitColumns([]);
					}}
					toolbar={[{ text: "Refresh", tooltipText: "Refresh List", prefixIcon: "e-refresh", id: "RefreshView" }]}
					toolbarClick={handleToolbarClick}
				>
					<ColumnsDirective>
						<ColumnDirective field="itemShortCode" headerText="Case Number" template={nameTemplate} isPrimaryKey={true} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="title" headerText="Subject" template={nameTemplate} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="status" headerText="Status" allowSorting={false} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="createdOn" headerText="Created On" valueAccessor={dateFormatter} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="createdBy" headerText="Login ID" template={nameTemplate} clipMode="EllipsisWithTooltip" />
						<ColumnDirective field="actions" headerText="Actions" template={actionsTemplate} textAlign="Right" allowSorting={false} clipMode="EllipsisWithTooltip" />
					</ColumnsDirective>
					<Inject services={[Page, Sort, Toolbar]} />
				</GridComponent>
			</div>

			<ReactSlidingPane
				isOpen={showSidePanel}
				width={"85%"}
				title={"Insight Detail"}
				onRequestClose={() => {
					setShowSidePanel(false);
				}}
			>
				<MemberInsight id={workItemId} />
			</ReactSlidingPane>
			<ThemeTooltip />
		</>
	);
};

export default MemberInsights;
